require('../../node_modules/uikit/dist/js/uikit.min.js');
import UIkit from 'uikit';

//UIkit.slider('.uk-slider', {autoplay:true});
$(document).on('click', ".mobile-menu-icon", function ( event ) {
    if($(this).find('input').is(':checked')) {
        $('.menu-container').addClass('open');
    } else {
        $('.menu-container').removeClass('open');
    }
} )
UIkit.scrollspy('.scroll-left-small', {cls:'uk-animation-slide-right-small', delay: 200});
UIkit.scrollspy('.scroll-right-small', {cls:'uk-animation-slide-right-small', delay: 200});
UIkit.scrollspy('.scroll-fade', {cls:'uk-animation-fade', delay: 200});

